import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import azitekTeam from '../assets/images/azitek_team.png';
import icon from '../assets/images/favicon.png';

import layoutStyle from '../styles/layout';
import { media, colors } from '../styles/constants';

const BackgroundContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};
  margin-top: 3.75rem;

  .fake-header-wrapper {
    display: flex;
    justify-content: center;

    p {
      text-align: center;
      font-size: 1.8rem;
      width: 950px;
      padding-bottom: 2rem;
      line-height: 1;

      @media (min-width: ${media.phoneLandscape}px) {
        font-size: 2.5rem;
      }
    }
  }

  > div {
    display: flex;
    flex-direction: column;

    ${layoutStyle}

    h2 {
      text-align: center;
      font-size: 1.8rem;
      padding-bottom: 2rem;

      @media (min-width: ${media.phoneLandscape}px) {
        font-size: 2.5rem;
        align-self: center;
      }

      @media (min-width: ${media.newsTextWidth}px) {
        width: 950px;
      }
    }
    h3 {
      font-size: 1.6rem;
    }
  }
`;

const HowItWorksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media (min-width: ${media.newsTextWidth}px) {
    width: 950px;
  }

  .image-midsection {
    @media (min-width: ${media.newsTextWidth}px) {
      display: flex;
      div {
        width: 100%;
      }
      img {
        width: 80%;
      }
    }
    @supports not (-moz-appearance: none) {
      @media (min-width: ${media.newsTextWidth}px) {
        img {
          width: 45%;
        }
      }
    }
  }

  &:before {
    content: ' ';
    position: absolute;
    font-size: 3rem;
    top: 0;
    left: -2.75rem;
    color: ${colors.blue};
    display: none;

    @media (min-width: ${media.phonePortrait}px) {
      display: inherit;
    }
  }

  p {
    margin-bottom: 2rem;
    line-height: 1.8;
  }
`;

const Signature = styled.div`
  display: flex;
  justify-content: end;
  text-align: right;
  img {
    width: 40px !important;
    height: 40px;
    border-radius: 50%;
    margin-right: 1rem;
  }
`;

const NewsWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;

  img {
    width: 100%;
    max-width: 950px;

    @media (min-width: ${media.navMaxLength}px) {
      width: 950px;
    }

    margin-bottom: 2rem;
    align-self: center;
  }

  ${TextContainer} {
    font-size: 1.2rem;
    line-height: 1.8;
    flex: 1 1 100%;
    padding-bottom: 2rem;
    align-self: center;
  }

  .gatsby-image-wrapper {
    width: 50%;
    margin: 2rem auto;
  }

  @media (min-width: ${media.tabletPortrait}px) {
    align-items: center;
    flex-direction: column-reverse;

    ${TextContainer} {
      font-size: 1.2rem;
      line-height: 1.8;
      flex: 1 0 55%;
      padding-bottom: 0;
    }

    .gatsby-image-wrapper {
      flex: 0 1 45%;
    }

    &:nth-child(2) {
      flex-direction: row-reverse;
    }
    &:nth-child(4) {
      flex-direction: row-reverse;
    }
  }

  &:not(:last-child) {
    padding-bottom: 1.5rem;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 2.5rem;
    }
  }

  :last-child {
    .gatsby-image-wrapper picture {
      max-width: 90%;
      max-height: 90%;
    }
  }

  :first-child ${TextContainer}:before {
    left: -2rem;
    color: ${colors.blue};
  }
`;

function NewsPageComponent({ backgroundColor = colors.lightGrey }) {
  return (
    <BackgroundContainer backgroundColor={backgroundColor}>
      <div>
        <div className="fake-header-wrapper">
          <p>
            <strong>
              Azitek Successfully Completes New Fundraising Round, Reaching a
              Total of{' '}
            </strong>
            €<strong>1M in Investment</strong>
          </p>
        </div>

        <HowItWorksContainer>
          <NewsWrapper>
            <TextContainer>
              <p>
                <strong>Azitek</strong>, an emerging startup based in Porto and
                incubated at <strong>UPTEC</strong>, has recently announced the
                successful completion of a{' '}
                <strong>new fundraising round</strong>. This operation was led
                by <strong>Beta Capital</strong>, co-investing with{' '}
                <strong>Portugal Ventures (Initial Investor)</strong> and{' '}
                <strong>Cedrus Capital</strong>, totaling{' '}
                <strong>€750,000</strong>. With this, Azitek surpasses{' '}
                <strong>€1 million</strong> raised in investment rounds,
                reinforcing its capacity for{' '}
                <strong>
                  expansion and innovation in the IoT technology sector
                </strong>
                , particularly in the{' '}
                <strong>
                  digitalization of assets in industrial environments.
                </strong>
              </p>
              <p>
                <strong>José Valente</strong>, CEO of Azitek, expressed great
                satisfaction with the success of the funding round: &#34;We are
                very pleased with the closing of this round, which will allow us
                to{' '}
                <strong>
                  strengthen the team, accelerate entry into new markets, and
                  continue developing our distinctive technology.&#34;
                </strong>{' '}
                He also highlighted the importance of investor support{' '}
                <strong>and the competence of the team</strong>, crucial
                elements to overcome the challenges of
                <strong>digitalizing processes and assets</strong>, thus
                improving the{' '}
                <strong>operational efficiency of customers.</strong>
              </p>
              <p>
                The recognition of{' '}
                <strong>
                  R&D eligibility by ANI - Agência Nacional de Inovação
                </strong>{' '}
                and the completion of this round will allow Azitek to accelerate
                the development of its technology, which has broad application
                in <strong>asset tracking using IoT systems</strong>. The
                solution is especially adapted to{' '}
                <strong>
                  optimize asset management and automate logistical processes in
                  various industrial sectors
                </strong>
                , with clients in{' '}
                <strong>Portugal, Spain, and the United States.</strong>
              </p>
              <p>
                The capital obtained will be crucial not only to{' '}
                <strong>
                  consolidate the innovation and robustness of the solutions
                </strong>{' '}
                but also to allow for{' '}
                <strong>team growth, expansion in the Spanish market,</strong>{' '}
                and <strong>exploration of other European markets</strong>,
                solidifying{' '}
                <strong>
                  {' '}
                  technological innovation for intelligent logistics and
                  manufacturing.
                </strong>
              </p>

              <p>
                <strong>Roberto Branco</strong>, CEO of Beta Capital, emphasizes
                that &#34;Azitek&#39;s solutions address several industry
                challenges, particularly in{' '}
                <strong>
                  logistical monitoring and optimization with real-time
                  information.
                </strong>{' '}
                We are excited about the{' '}
                <strong>development roadmap of their products</strong>, aiming
                to increase the{' '}
                <strong>value proposition for their clients</strong> and
                implement them globally.&#34;
              </p>

              <p>
                For <strong>Pedro de Mello Breyner</strong>, Member of the Board
                of Directors at Portugal Ventures, this new round raised by
                Azitek reinforces the initial investment made in 2019 to achieve
                the{' '}
                <strong>
                  Minimum Viable Product and its commercialization in the global
                  market.
                </strong>{' '}
                &#34;With this new capital round, Azitek is now prepared to{' '}
                <strong>
                  consolidate the robustness of its technological solutions,
                  increase the team, solidify its position in the Spanish
                  market, and explore presence in other European markets
                </strong>
                . These are very ambitious goals for this new phase, and we are
                confident that the Azitek team will be able to meet them
                successfully.&#34;
              </p>
              <p></p>
              <Signature>
                <img src={icon} />
                <p>
                  <strong>
                    Azitek Team
                    <br /> Jun 2024
                  </strong>
                </p>
              </Signature>
            </TextContainer>
            <img
              className="origin-story-img"
              src={azitekTeam}
              imgStyle={{ height: 'auto' }}
            />
          </NewsWrapper>
        </HowItWorksContainer>
      </div>
    </BackgroundContainer>
  );
}

NewsPageComponent.propTypes = {
  title: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default NewsPageComponent;
